table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  cursor: pointer;
}

input {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  box-sizing: border-box;
}
